'use strict';

class Index {
	constructor() {
		this.init();
	}

	protected init() {
	}
	public domLoad() {
		$(window).on('resize', () => {this.resize();});
		$(window).on('scroll', () => {this.scroll();});
		this.resize();
		this.scroll();
	}
	public load() {
		$('.header .hdr_wrpr .menu a').on('click', (event) => {
			event.preventDefault();
			$('.g_menu').addClass('show');
		});
		$('.g_menu .menu_wrpr .btn_close a').on('click', (event) => {
			event.preventDefault();
			$('.g_menu').removeClass('show');
		});
		$('#lineup .prod .detail_wrpr ul li a.btn_popup').on('click', (event) => {
			event.preventDefault();
			$('html, body').addClass('fix');
			const tgt = $(event.currentTarget).attr('href');
			$(tgt).fadeIn();
		});
		$('.popup .pop_wrpr .btn_close a').on('click', (event) => {
			event.preventDefault();
			$('html, body').removeClass('fix');
			$('.popup').fadeOut();
		});
		$('.to_top .btn_to_top a').on('click', (evetn) => {
			event.preventDefault();
			$('html,body').stop(true, false).animate({scrollTop: '0px'}, 500, 'easeInOutSine');
		});
		$('.footer ul li a').on('click',(event) => {
			const tgt = $(event.currentTarget).attr('href');
			if (tgt.indexOf('#') >= 0) {
				event.preventDefault();
				if ($(tgt).length > 0) {
					const py = $(tgt).offset().top;
					$('html,body').stop(true, false).animate({scrollTop: py + 'px'}, 500, 'easeInOutSine');
				}
			}
		});
		$('.g_menu .menu li a').on('click',(event) => {
			const tgt = $(event.currentTarget).attr('href');
			if (tgt.indexOf('#') >= 0) {
				event.preventDefault();
				$('.g_menu').removeClass('show');
				if ($(tgt).length > 0) {
					const py = $(tgt).offset().top;
					$('html,body').stop(true, false).animate({scrollTop: py + 'px'}, 500, 'easeInOutSine');
				}
			}
		});
		this.resize();
		this.scroll();
	}
	public resize = () => {

	}
	public scroll = () => {
		let scTop = $(window).scrollTop();
		let hig = $(window).innerHeight();
		let per = 0.8;
		var pos = scTop + hig*per;
		$('.scrl').each((i,o) => {
			let top = $(o).offset().top;
			if (!$(o).hasClass('show') && top < pos) {
				$(o).addClass('show');
			}
		});
		//var moviePos = $('#movie').offset().top;
		var aboutPos = $('#about').offset().top;
		var okinawanPos = $('#okinawan').offset().top;
		var lineupPos = $('#lineup').offset().top;
		var contactCompanyPos = $('#contact_company').offset().top;

		/*if (pos > moviePos && pos < aboutPos) {
		 	$('.side_nav li').removeClass('crnt');
		 	$('.side_nav .nav_movie').addClass('crnt');
		} else */if (pos > aboutPos && pos < okinawanPos) {
		 	$('.side_nav li').removeClass('crnt');
		 	$('.side_nav .nav_about').addClass('crnt');
		} else if (pos > okinawanPos && pos < lineupPos) {
		 	$('.side_nav li').removeClass('crnt');
		 	$('.side_nav .nav_okinawan').addClass('crnt');
		} else if (pos > lineupPos && pos < contactCompanyPos) {
			$('.side_nav li').removeClass('crnt');
			$('.side_nav .nav_lineup').addClass('crnt');
		} else if (pos > contactCompanyPos) {
			$('.side_nav li').removeClass('crnt');
			$('.side_nav .nav_contact_company').addClass('crnt');
		} else {
			$('.side_nav li').removeClass('crnt');
		}

		// logo
		var logoPos = $('.header .hdr_wrpr h1').offset().top;
		var logoH = $('.header .hdr_wrpr h1').height();
		if (logoPos + logoH < scTop) {
			if (!$('.wrpr').hasClass('logo_s')){
				$('.wrpr').addClass('logo_s')
			}
		} else {
			if ($('.wrpr').hasClass('logo_s')){
				$('.wrpr').removeClass('logo_s')
			}
		}
	}
}

var main = new Index();
window['main'] = main;
if (document.readyState !== 'loading') {
	main.domLoad();
} else {
	document.addEventListener('DOMContentLoaded', function(){window['main'].domLoad();});
}
window.addEventListener('load',function(){window['main'].load();});
